import React from "react";
import Img from "gatsby-image"
import { withTranslation } from "react-i18next";
import SmartForm from "../SmartForm"
import SocialShare from "../SocialShare"
import Heading from "../Heading"
import './home_office.scss'
class Home_office extends React.Component {

    render() {

        const { data, content, t } = this.props
        return(
            <div id="home_office" class="blog-area blog-no-sidebar pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                    <div class="row">
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image1.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image2.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-12 pull-left">
                                            <Img sizes={data.image3.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image4.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-6 pull-left">
                                            <Img sizes={data.image5.childImageSharp.fluid} />
                                        </div>
                                        <div class="blog-img img-md col-sm-12 pull-left">
                                            <Img sizes={data.image6.childImageSharp.fluid} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev mt-60">
                                <SocialShare title={data.title} hashtags="home-office" />
                            </div>
                            <div class="question-area">
                                <h3>{t('would_you_like_to_request_an_offer?')}</h3>
                                <SmartForm 
                                    successTitle={t('your_offer_request_has_been_sent_successfully')}
                                    successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                    formClassName="home_office-form" 
                                    formName="home_office" 
                                    mailSubject="Tiliana home office ajánlatkérés"
                                    stateProps={["name", "email", "message"]}>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label>{t('my_name')}</label>
                                                <input name="name" type="text" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label>{t('my_email')}</label>
                                                <input name="email" type="email" required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="single-input">
                                                <label>{t('offer_request')}</label>
                                                <textarea className="form-control" name="message" required />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="single-input">
                                                <button className="sent-btn" type="submit">
                                                    {t('request_offer')}
                                                </button>
                                            </div>
                                        </div>                                   
                                    </div>
                                </SmartForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Home_office)